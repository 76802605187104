import React, { Component } from 'react';
//import api from '../../services/api';
import { Link } from 'react-router-dom';
import logo from '../../img/logo-giovanni-mesquita.svg';

export default class Mentoria extends Component {
    
    componentDidMount() {
        this.props.setMenu(false);
    }

    render() {
        return (
            <section className="mentoria">
                <header>
                    <Link to="/" className="about">
                        <img src={logo} className="logo" alt="logo" />
                    </Link> 
                </header>

                <h1>Mentoria</h1>
                <h2>
                    UX aplicado ao negócio
                </h2>
                <h3>
                    Acompanhamento e orientações para startups e empresas que querem resolver problemas reais dos seus usuários.
                </h3>
                <figure>
                    <img src="" alt="Mentoria para startups"/>
                </figure>
                <p>
                    Oficinas, mentoria e entregas para startups que 
                    precisam resolver problemas reais dos seus usuários.
                </p>
                <h4>
                    Como funciona
                </h4>
                <p>
                    Primeiro, uma conversa para entender o contexto da 
                    empresa, da equipe e da solução e quais são seus desafios. 
                    A partir daí, juntos definimos um programa sob medida 
                    para elevar a maturidade do produto ou serviço.
                </p>
                <figure>
                    <img src="" alt="Mentoria para startups"/>
                </figure>
                <p>
                A mentoria é composta por fases, os Ciclos de UX. 
                </p>
                <h4>
                    Conheça algumas atividades que podem ser 
                    desenvolvidas em cada ciclo
                </h4>
                <ul>
                    <li>
                        Visão geral de UX, valor e  entregáveis
                    </li>

                    <li>
                        Definição do Propósito
                    </li>

                    <li>
                        Usuários e personas
                    </li>

                    <li>
                        Mapeamento de processos: jornada do usuário e blueprints de serviço
                    </li>

                    <li>
                        Métricas de UX
                    </li>

                    <li>
                        Quadro comparativo: benchmarking
                    </li>

                    <li>
                        Entrevistas, pesquisa de campo e coleta de feedbacks
                    </li>

                    <li>
                        Testes e análise de usabilidade
                    </li>

                    <li>
                        Cocriação da lista de funcionalidades e definição do roadmap
                    </li>

                    <li>
                        Desenho de protótipos de baixa, média e alta fidelidade.
                    </li>

                    <li>
                        Elaboração do Design System (padrões de estilo)
                    </li>

                    <li>
                        Desenvolvimento do front-end e integrações.
                    </li>
                </ul>
            </section>
        )
    }
}