import React, { Component } from 'react';
//import api from '../../services/api';
import { Link } from 'react-router-dom';
import logo from '../../img/logo-giovanni-mesquita.svg';

export default class Treinamento extends Component {
    
    componentDidMount() {
        this.props.setMenu(false);
    }
    
    render() {
        return (
            <section className="treinamento">
                <header>
                    <Link to="/" className="about">
                        <img src={logo} className="logo" alt="logo" />
                    </Link> 
                </header>

                <h1>Treinamento</h1>
            </section>
        )
    }
}