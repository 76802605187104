import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo-giovanni-mesquita.svg';

export default class Error404 extends Component {
    
    componentDidMount() {
        this.props.setMenu(false);
    }
    
    render() {
        return (
            <section className="error404">
                <header>
                    <Link to="/" className="about">
                        <img src={logo} className="logo" alt="logo" />
                    </Link> 
                </header>
                <h3>Página não encontrada.</h3>
            </section>
        );
    }
}