import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo-giovanni-mesquita.svg';

export default class Atuacao extends Component {
    
    componentDidMount() {
        this.props.setMenu(false);
    }
    
    render() {
        return (
            <section className="atuacao">
                <header>
                    <Link to="/" className="about">
                        <img src={logo} className="logo" alt="logo" />
                    </Link> 
                </header>
                <article>
                    <h1>Atuação</h1>
                    <h2>
                        Um pouco da minha jornada
                    </h2>
                    <h4>
                        Tenho acompanhado e orientado startups e empresas que querem 
                        inovar resolvendo problemas reais dos seus usuários
                    </h4>
                    <figure>
                        <img src="./img/foco-no-design.jpg" alt="Equipe Sempre Design" />
                    </figure>
                    <p>
                        Sou sócio fundador da <a href="https://sempredesign.com.br/">Sempre Design</a>, 
                        empresa pioneira em UX e design estratégico no ES. 
                    </p>
                    <p>
                        Participei de projetos ligados à inovação em empresas de diversas áreas, 
                        desde transporte e mobilidade a saúde, passando por metalmecânica, eventos, 
                        telecomunicações, construção civil, setor público e entidades de ensino.
                    </p>

                    <h3>Palestras e workshops</h3>
                    <h4>
                        Informação, quando colocada em prática, gera conhecimento. Quando mais pessoas fazem, gera cultura.
                    </h4>
                    <figure>
                        <img src="./img/palestrante.jpg" alt="Facilitando workshop sobre inovação" />
                    </figure>
                    <p>
                        Falar com pessoas, possibilitar novos pontos de vista e 
                        facilitar para que as equipes alcancem seus objetivos tem sido 
                        um dos trabalhos que mais me dão prazer.
                    </p>
                    <details>
                        <summary>
                            Palestras ministradas
                        </summary>
                        <figure>
                            <img src="./img/workshop.jpg" alt="Facilitando workshop sobre inovação" />
                        </figure>
                        <ul>
                            <li>
                                <b>UX Além das telas</b> 
                                <br/>Ciclo de Palestras - Tecvitória - 2019
                            </li>
                            <li>
                                <b>O Design na era da experimentação</b> 
                                <br/>5 em 1 - Tecvitória - 2019
                                <br/>Faesa Summit - Faesa - 2019
                            </li>
                            <li>
                                <b>UX produto e mercado</b> 
                                <br/>Curso de Administração - UVV - 2019
                            </li>
                            <li>
                                <b>O que é UX Design</b> 
                                <br/>DTI - Grupo Águia Branca - 2018
                            </li>
                            <li>
                                <b>
                                Design to make a difference: 
                                inclusive design and interactions
                                (Rama Gheerawo)
                                </b> 
                                <br/>ILA Redux Vitória - IxDA / Brooder - 2019
                            </li>
                            <li>
                                <b>Produto, serviço e mercado</b> 
                                <br/>Azys Talk - Azys Aceleradora - 2019
                            </li>
                            <li>
                                <b>UX e inovação</b> 
                                <br/>Unimed Cachoeiro - 2019
                            </li>
                            <li>
                                <b>A Abordagem UX dentro e fora do meio digital</b> 
                                <br/>Papo IEL - Findes - 2020
                            </li>
                            <li>
                                <b>UX dá lucro</b> 
                                <br/>Sebrae Startup Summit - Sebrae - 2019
                            </li>
                            <li>
                                <b>Inovar com UX em tempos de crise</b> 
                                <br/>Webinar Brooder - Youtube - 2020
                            </li>
                        </ul>
                    </details>    

                    <h3>
                        Treinamentos
                    </h3>
                    <h4>
                        O risco de fazer inovação é que tudo pode mudar.
                    </h4>
                    <figure>
                        <img src="./img/formandos-ciclos-de-ux.jpg" alt="Workshops de Design Thinking"/>
                    </figure>
                    <p>
                        Desenvolvemos os <em>Ciclos de UX</em> na Sempre Design no dia a dia, 
                        atendendo desafios de startups e empresas em processo de transformação 
                        digital em diversos portes e estágios.
                    </p>
                    <p>
                        Trata-se de um conjunto de métodos e entregáveis para realizar projetos, 
                        produtos e serviços ágeis, lucrativos e sustentáveis, com foco no cliente.
                    </p>
                    <details>
                        <summary>
                            Cursos realizados
                        </summary>
                        <ul>
                            <li>
                                <b>Curso Ciclos de UX</b> 
                                <br/>Sempre Design - Aberto ao público - Tecvitória - 2020
                            </li>
                            <li>
                                <b>Prototipação</b> 
                                <br/>Trilha Startup - Sebrae - 2020
                            </li>
                            <li>
                                <b>Foco em UX</b> 
                                <br/>Unimed Innovation Experience - UVV - 2019
                            </li>
                            <li>
                                <b>Curso Ciclos de UX in Company</b> 
                                <br/>Sob medida, em diversas empresas - 2018-2020
                            </li>
                            <li>
                                <b>Ferramentas da Inovação</b> 
                                <br/>Inova Comércio - Grupo Águia Branca - 2019
                            </li>
                            <li>
                                <b>Pensando ágil com Trello</b> 
                                <br/>Vix Logística - Grupo Águia Branca - 2019
                            </li>
                        </ul>
                    </details>

                    <h3>
                        Consultoria
                    </h3>
                    <h4>
                        O cuidado com o detalhe faz toda a diferença
                    </h4>
                    <p>
                        Pesquisa, cocriação, prototipação, teste, desenvolvimento. Para produtos digitais e físicos.
                        Não há limite para inovar quando você busca direto da fonte, do mercado, das pessoas que usam.
                    </p>
                    <details>
                        <summary>
                            Alguns trabalhos bacanas
                        </summary>
                        <ul>
                            <li>
                                <b>E-Docs</b> 
                                <br/>
                                Ciclo de UX para reformular o Sistema de Gestão 
                                eletrônica de Documentos do Governo do ES. 
                                <em>Prodest</em>
                            </li>
                            <li>
                                <b>Driver Analytics</b> 
                                <br/>
                                Ciclo de UX para a concepção da nova interface para 
                                o sistema de análise da condução do motorista.
                                <em>Motora</em>
                            </li>
                            <li>
                                <b>Dersalis</b> 
                                <br/>
                                Interface para o aplicativo de controle de saúde e orientações ao paciente.
                                <em>Dersalis</em>
                            </li>
                            <li>
                                <b>Canal de Denúncias</b> 
                                <br/>
                                Prototipação, teste e desenvolvimento do sistema de registro de ocorrências.
                                <em>Unionlab</em>
                            </li>
                            <li>
                                <b>GeoTV</b> 
                                <br/>                                
                                Projeto gráfico e desenvolvimento de interface do aplicativo de entretenimento a bordo.
                                <em>Geocontrol</em>
                            </li>
                            <li>
                                <b>Pool de Veículos</b> 
                                <figure>
                                    <img src="./img/prototipagem.jpg" alt="Cocriando um protótipo"/>
                                </figure>
                                Ciclo de UX para a concepção e desenvolvimento do aplicativo solicitação de viagens.
                                <em>Vix Logística</em>
                            </li>
                            <li>
                                <b>Nosso Grupo</b> 
                                <br/>
                                Pesquisa e concepção de rede social corporativa, para uso restrito aos funcionários.
                                <em>Grupo Águia Branca</em>
                            </li>
                            <li>
                                <b>Reformulação de serviços WIG</b> 
                                <figure>
                                    <img src="./img/wig.jpg" alt="Workshops de Design Thinking"/>
                                </figure>
                                Workshop com as equipes da empresa e convidados para a definição de um novo modelo de negócio na área de construção civil.
                                <em>Wig - Construção e acabamento</em>
                            </li>
                        </ul>
                    </details>

                </article>
            </section>
        );
    }
}