import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo-giovanni-mesquita.svg';

export default class Atuacao extends Component {
    
    componentDidMount() {
        this.props.setMenu(false);
    }
    
    render() {
        return (
            <section className="atuacao">
                <header>
                    <Link to="/" className="about">
                        <img src={logo} className="logo" alt="logo" />
                    </Link> 
                </header>
                <article>
                    <h1>Propósito</h1>
                    <h2>
                        Faz a vida valer a pena
                    </h2>

                    <figure>
                        <img src="./img/familia-bolinhas.jpg" alt="Família" />
                    </figure>
                    <h4>
                         
                    </h4>
                        <h4>O que te move?</h4> 
                    <p>
                        Coisas simples me dão energia para encarar a luta do dia a dia. 
                        Elas me fazem lembrar que é preciso lutar pelos valores em que acredito. 
                        O trabalho sem propósito nos faz menos humanos. Carinho, cuidado, atitude.
                        É isso. Quando há coerência, tudo funciona em sintonia.
                    </p>
                    <p>
                        Algumas iniciativas e projetos que tenho participado.
                    </p>

                    <h3>
                        Cultura Vitória
                    </h3>
                    <figure>
                        <img src="./img/site-cultura-vitoria.jpg" alt="Site Cultura Vitória" />
                    </figure>
                    <h4>
                        A cultura que a gente faz no dia a dia é nosso bem mais precioso.
                        É a nossa identidade. 
                    </h4>
                    <p>
                        Site lançado em 2014 para o cadastro de bens culturais imateriais.
                    </p>
                    <p>
                        <a href="https://culturavitoria.com.br/o-projeto" target="culturavitoria">Conhecer o projeto</a>
                    </p>

                    <h3>
                        Challenge Grande Vitória
                    </h3>
                    <figure className="embed">
                        <img src="./img/challenge-gv.jpg" alt="Challenge Grande Vitória" />
                    </figure>
                    <h4>
                        Empreender com impacto é um ato de coragem e obstinação.
                    </h4>
                    <p>
                        Participação como voluntário na primeira edição do evento coordenado pela Baanko,
                        com 3 semanas de capacitações para startups de impacto socio ambiental.
                    </p>

                    <h3>
                        AUATI
                    </h3>
                    <figure className="embed">
                        <img src="./img/site-auati.jpg" alt="Site Auati" />
                    </figure>
                    <h4>
                        No Auati temos a oportunidade de colocar em prática valores em que acreditamos.
                    </h4>
                    <p>
                        Um projeto de impacto, de autoria da minha esposa Rafaela, que tenho 
                        a felicidade de colaborar facilitando os Ciclos de UX e coordenando 
                        o desenvolvimento da nova plataforma.
                        Pra quem não conhece a plataforma, sugiro uma visita ao site <a href="https://auati.com.br">
                            auati.com.br
                        </a>
                    </p>
                    <ol>
                        <li>
                            <strong>Cuidado e atenção às pessoas</strong>, com um atendimento humano e com muito respeito.
                        </li>
                        <li>
                            <strong>Impacto positivo na sociedade</strong>, como uma alternativa inclusiva de fazer negócios, 
                            baseada na conexão entre pessoas que têm interesses em comum.
                        </li>
                        <li>
                            A força do <strong>design</strong>, na estratégia e no visual, para fazer tudo acontecer.
                        </li>
                    </ol>

                </article>
            </section>
        );
    }
}