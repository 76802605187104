import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import './App.css';
import Menu from './components/menu';

import Start from './pages/start';
import Mentoria from './pages/mentoria';
import Treinamento from './pages/treinamento';
import Ajuda from './pages/ajuda';
import Cases from './pages/cases';
import Error404 from './pages/error404';
import Atuacao from './pages/atuacao';
import Minibio from './pages/minibio';
import Proposito from './pages/proposito';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.setMenu = this.setMenu.bind(this);

    this.state = {
      showMenu: false
    }
  }

  setMenu(value) {
    this.setState({
      showMenu: value
    });
    console.log(this.state.showMenu);
  }

  render() {
    return (
      <div className={"App" + (this.state.showMenu ? " home" : "")}>
        <Router basename={process.env.PUBLIC_URL}>
          <Menu
            showMenu={this.state.showMenu}
            setMenu={this.setMenu}
            />
          <Switch>

              <Route exact path="/">
                <Start 
                  setMenu={this.setMenu}
                />
              </Route>

              <Route path="/mentoria">
                <Mentoria 
                  setMenu={this.setMenu}
                />
              </Route>

              <Route path="/treinamento">
                <Treinamento 
                  setMenu={this.setMenu}
                />
              </Route>

              <Route path="/ajuda">
                <Ajuda 
                  setMenu={this.setMenu}
                />
              </Route>

              <Route path="/minibio">
                <Minibio 
                  setMenu={this.setMenu}
                />
              </Route>

              <Route path="/proposito">
                <Proposito 
                  setMenu={this.setMenu}
                />
              </Route>

              <Route path="/cases/:id">
                <Cases
                  setMenu={this.setMenu}
                  />
              </Route>

              <Route path="/atuacao">
                <Atuacao
                  setMenu={this.setMenu}
                  />
              </Route>

              <Route>
                  <Error404
                  setMenu={this.setMenu}
                  />
              </Route>

          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;