import React, { Component } from 'react';
//import api from '../../services/api';

export default class Start extends Component {

    componentDidMount() {
        this.props.setMenu(true);
    }
    
    render() {
        return (
            <section className="start"></section>
        )
    }
}