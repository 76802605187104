import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo-giovanni-mesquita.svg';

export default class Atuacao extends Component {
    
    componentDidMount() {
        this.props.setMenu(false);
    }
    
    render() {
        return (
            <section className="atuacao">
                <header>
                    <Link to="/" className="about">
                        <img src={logo} className="logo" alt="logo" />
                    </Link> 
                </header>
                <article>
                    <h1>Mini Bio</h1>
                    <h2>
                        Em poucas palavras...
                    </h2>

                    <figure className="perfil">
                        <a href="./img/foto-giovanni-mesquita-2.jpg" download title="Clique para baixar">
                            <img src="./img/foto-giovanni-mesquita-2.jpg" alt="Foto para divulgação" />
                        </a>
                    </figure>
                    <h4>
                        Giovanni Mesquita é apaixonado por design e inovação. Fundador 
                        da Sempre Design e criador da metodologia dos Ciclos de UX, 
                        acredita no poder da colaboração e da inclusão das perspectivas 
                        dos usuários para criar soluções sustentáveis e eficazes que 
                        gerem valor real para as pessoas.
                    </h4>
                    <p>
                        Formado em Artes Plásticas, programador full stack autodidata, 
                        designer e empreendedor, sócio fundador da Sempre Design. 
                    </p>
                    <p>
                        Como especialista em UX Design, com mais de 15 anos de experiência, 
                        lidera equipes e auxilia empresas, startups e projetos a tornarem 
                        seus negócios escaláveis e sustentáveis, utilizando ferramentas de inovação, 
                        com foco nas necessidades e contextos dos usuários.
                    </p>
                    <p>
                        <a href="https://docs.google.com/document/d/18EZuzjhWBxGLIJDjp4tGc46iqAQE1YIDoKZ1beltNgY/export?format=pdf" target="curriculo">Baixar currículo completo</a>
                    </p>
                    <h3>
                        Workshops
                    </h3>

                    <figure className="embed">
                        <div>
                            <iframe width="100%" height="315" 
                            src="https://www.youtube.com/embed/OBvcBAPHcU0" frameborder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen title="Definição de Propósito e Planejamento GM Industrial"></iframe>
                        </div>
                        <figcaption>
                            Definição de Propósito e Planejamento GM Industrial
                        </figcaption>
                    </figure>

                    <figure className="embed">
                        <div style={{paddingBottom:"calc(16/9)"}}>
                            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6590624371633635328?compact=1" 
                            height="315" width="100%" frameborder="0" 
                            allowfullscreen="" title="Definição de propósito Findes"></iframe>
                        </div>
                        <figcaption>
                            Definição de Propósito Findes
                        </figcaption>
                    </figure>

                    <figure>
                        <video src="./img/planejamento-2020-tecvitoria.mp4" controls
                        width="100%" title="Planejamento Estratégico TecVitória - 2020"></video>
                        <figcaption>
                            Planejamento Estratégico TecVitória - 2020
                        </figcaption>
                    </figure>

                    <figure>
                        <video src="./img/video-inova-comercio.mp4" controls
                        width="100%" title="Treinamento Ferramentas da Inovação - Divisão Comércio - Grupo Águia Branca"></video>
                        <figcaption>
                            Treinamento Ferramentas da Inovação - Divisão Comércio - Grupo Águia Branca
                        </figcaption>
                    </figure>
                </article>
            </section>
        );
    }
}