import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './menu.css';
import frase from './ux-service-design-interactive.svg';
import logo from '../../img/logo-giovanni-mesquita.svg';
import puxador from './puxador.svg';

export default class Menu extends Component {

    render() {
        return (
            <menu className={this.props.showMenu ? "" : "recolhido"}>
                <div>
                    <Link to="/">
                        <img src={puxador} className="puxador" alt="Exibir o menu" />
                    </Link> 
                    <img src={frase} className="frase" alt="UX, Design de Serviços, Design de Interação" />
                    <Link to="/" className="about">
                        <img src={logo} className="logo" alt="logo" />
                    </Link> 
                    <nav>
                        <ul>
                            <li>
                                <Link to={`/minibio`}>Minibio</Link>
                            </li>
                            <li>
                                <Link to={`/atuacao`}>Atuação</Link>
                            </li>
                            <li>
                                <Link to={`/proposito`}>Propósito</Link>
                            </li>
                        </ul>
                    </nav>                 
                    <div className="social">
                        
                        <a href="https://instagram.com/giovanni_ux" 
                        target="Instagram" 
                        className="instagram" 
                        title="Ver minhas postagens no Instagram">
                            <span>Instagram</span>
                        </a>

                        <a href="https://linkedin.com/in/giovannimesquita" 
                        target="Linkedin" 
                        className="linkedin" 
                        title="Ver meu perfil no Linkedin">
                            <span>Linkedin</span>
                        </a>

                        <a href="https://api.whatsapp.com/send?phone=5527997189868&text=Oi%2C%20Giovanni!%20Vamos%20conversar%3F" 
                        target="Whatsapp" 
                        className="whatsapp" 
                        title="Mandar uma mensagem via Whatsapp">
                            <span>Whatsapp</span>
                        </a>

                    </div>
                </div>
            </menu>
        )
    }
}